import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { AlertConfirm, Button, TableCustom } from '../../../../components';
import { one } from '../../../../helpers/actions/leroymerlin';
import MaterialModal from './MaterialModal';
import MaterialCatalogModal from './MaterialCatalogModal';
import defaultImage from '../../../../assets/img/image_placeholder.jpg';
import { moneyFormatter } from '../../../../helpers/formatters';
import get from 'lodash/get';
import _ from 'lodash';

class Materials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operation: null,
      material: null,
      materialDelete: null,
      fromCatalogMat: false
    };
  }

  componentWillMount() {
    let { operation } = this.props;
    let op = _.cloneDeep(operation);
    this.setState(ps => ({ ...ps, operation: op }));
  }

  componentWillReceiveProps({ operation }) {
    let op = _.cloneDeep(operation);
    this.setState(ps => ({ ...ps, operation: op }));
  }

  addMatFromCatalog(selected) {
    this.setState({ fromCatalogMat: false });
    let { operation } = this.state;

    selected.forEach(material => {
      let { dispatch } = this.props;

      dispatch(one(material.id))
        .then(res => {
          operation.materials.push({
            name: `${material?.model?.label || material?.administrativeLabel} - ${material?.id}`,
            unit_price: (material.price / 1.2).toFixed(2),
            cant: material.cant ? material.cant : 1,
            unit: material.unitSale ? material.unitSale : '',
            id: material.id,
            image: res && res.data && res.data.length ? res.data[0].url : null
          });

          this.props.updateOperation(operation);
        })
        .catch(() => { });
    });
  }

  showAddMaterial() {
    this.setState({
      material: {
        unit_price: 1,
        cant: 1,
        image: defaultImage
      }
    });
  }

  saveMaterial(material) {
    let { operation } = this.state;

    material.id = Math.floor(Math.random() * 255);
    operation.materials.push(material);

    this.props.updateOperation(operation);
  }

  showUpdate(material) {
    this.setState({ material });
  }

  updateMaterial(material) {
    let { operation } = this.state;

    if (operation && operation.materials) {
      let index = operation.materials.findIndex(t =>
        material._id ? t._id === material._id : t.id === material.id
      );

      if (index > -1) {
        operation.materials[index] = material;
        this.props.updateOperation(operation);
      }
    }
  }

  deleteMaterial(material) {
    this.setState({ materialDelete: null });
    let { operation } = this.state;

    if (operation && operation.materials) {
      let index = operation.materials.findIndex(t =>
        material._id ? t._id === material._id : t.id === material.id
      );

      if (index > -1) {
        operation.materials.splice(index, 1);
        this.props.updateOperation(operation);
      }
    }
  }

  getMaterialsData() {
    let { operation } = this.state;
    let materials = [];

    if (operation && operation.materials) {
      materials = operation.materials.map(material => {
        let image = material.image ? material.image : defaultImage;

        return {
          className: '',
          data: [
            {
              className: '',
              value: (
                <div
                  className="piece-surface-image"
                  style={{
                    backgroundImage: `url('${image}')`
                  }}
                />
              )
            },
            { className: '', fixed: false, value: get(material, 'name', '') },
            {
              className: 'text-right',
              fixed: false,
              value: `${get(material, 'cant', 0)} / ${get(
                material,
                'unit',
                '-'
              )}`
            },
            {
              className: 'text-right',
              fixed: false,
              value: get(material, 'unit_price', '')
            },
            {
              className: 'text-right',
              fixed: false,
              value: moneyFormatter(
                material.unit_price * material.cant +
                material.unit_price * material.cant * (operation.tva / 100)
              )
            },
            {
              className: 'text-right',
              properties: { width: '7%' },
              actions: true,
              fixed: true,
              value: [
                <Button
                  key={`edit-${material._id}`}
                  id={`edit-${material._id}`}
                  className={'btn-icon ml-1'}
                  color="info"
                  size="sm"
                  onClick={() => this.showUpdate(material)}
                >
                  <i className="fa fa-pencil-alt icon-action" />
                </Button>,
                <UncontrolledTooltip
                  key={`tooltip-edit-${material._id}`}
                  placement="left"
                  target={`edit-${material._id}`}
                  delay={0}
                >
                  <Trans>Edit</Trans>
                </UncontrolledTooltip>,
                <Button
                  key={`remove-${material._id}`}
                  id={`remove-${material._id}`}
                  className={'btn-icon ml-1'}
                  color="danger"
                  size="sm"
                  onClick={() => this.setState({ materialDelete: material })}
                >
                  <i className="fa fa-trash icon-action" />
                </Button>,
                <UncontrolledTooltip
                  key={`tooltip-remove-${material._id}`}
                  placement="left"
                  target={`remove-${material._id}`}
                  delay={0}
                >
                  <Trans>Delete</Trans>
                </UncontrolledTooltip>
              ]
            }
          ]
        };
      });
    }

    return materials;
  }

  render() {
    let { material, materialDelete, fromCatalogMat } = this.state,
      { t } = this.props;
    let rows = this.getMaterialsData();

    return (
      <Card>
        <CardBody className={'p-2'}>
          <Row>
            <Col xs={6} className={'text-left'}>
              <strong className={'p-1'}>
                <Trans>Materials</Trans>
              </strong>
            </Col>
            <Col xs={6} className={'text-right'}>
              <Button
                className={'m-1 mr-1'}
                size="sm"
                color={'light'}
                onClick={() => this.setState({ fromCatalogMat: true })}
              >
                <i className="now-ui-icons ui-1_simple-add" />{' '}
                <Trans>Catalog</Trans>
              </Button>
              <Button
                className={'m-1'}
                size="sm"
                color={'light'}
                onClick={() => this.showAddMaterial()}
              >
                <i className="now-ui-icons ui-1_simple-add" />{' '}
                <Trans>Material</Trans>
              </Button>
            </Col>
          </Row>
          {rows.length ? (
            <Row>
              <Col xs={12}>
                <TableCustom
                  minWidth={767}
                  className={'devi-item-material-table'}
                  accordion={true}
                  principal={1}
                  heads={[
                    { value: t('Image') },
                    { value: t('Name') },
                    { value: t('Amount'), className: 'text-right' },
                    { value: t('Unit Price'), className: 'text-right' },
                    { value: t('Price'), className: 'text-right' },
                    { value: '', className: 'text-right' }
                  ]}
                  rows={rows}
                  emptyMessage={t('No materials found')}
                />
              </Col>
            </Row>
          ) : null}
        </CardBody>

        {!!material ? (
          <MaterialModal
            show={!!material}
            material={material}
            onCancel={() => this.setState({ material: null })}
            onConfirm={material =>
              this.setState(
                prevState => {
                  return { ...prevState, material: null };
                },
                () => this.saveMaterial(material)
              )
            }
            onUpdate={material =>
              this.setState(
                prevState => {
                  return { ...prevState, material: null };
                },
                () => this.updateMaterial(material)
              )
            }
          />
        ) : null}

        {fromCatalogMat ? (
          <MaterialCatalogModal
            show={fromCatalogMat}
            onCancel={() => this.setState({ fromCatalogMat: false })}
            onConfirm={material =>
              this.setState({ fromCatalogMat: false }, () =>
                this.addMatFromCatalog(material)
              )
            }
          />
        ) : null}

        {!materialDelete ? null : (
          <AlertConfirm
            message={'The material cannot be recovered'}
            onCancel={() => this.setState({ materialDelete: null })}
            onConfirm={() => this.deleteMaterial(materialDelete)}
          />
        )}
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(Materials));
